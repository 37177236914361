/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-19",
    versionChannel: "nightly",
    buildDate: "2024-08-19T00:05:58.171Z",
    commitHash: "cde0ef593c06dce85921f4e3ea590205f9ed464b",
};
